import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import "./Game.css";
import data from "../../JSON/gameData.json";
import GameCard from "../GameCard/GameCard";
import ViewMaster from "../ViewMaster/ViewMaster";
import { FaArrowCircleUp } from "react-icons/fa";
import { scrollToTop } from "../../Custom/custom";
import AdComponent from '../AdComponent/AdComponent';
import { Modal, Spinner } from 'react-bootstrap';

function Game() {
  const { gameId } = useParams();
  const [game, setgame] = useState({})
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);


  useEffect(() => {
    if (gameId) {
      let loadGame = data.find(x => x.shortUrl == gameId)
      if (loadGame) {
        setgame({ ...loadGame })
        document.title = loadGame.title + " | Play Poki Games";
        window.gtag("event", "game", {
          event_category: "game",
          event_label: loadGame?.title
        });
      }
    }
  }, [gameId])
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [gameId])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    // setShow(true);
  }, [])

  return (
    <>
      <div>
        <div className='scrollTop cursor-pointer' onClick={() => scrollToTop()}><FaArrowCircleUp size={30} color='#f67905' /></div>
        <div className="bgContent">
          <div className="my-container py-4">
            <div className="common-game-bg-container" style={{ width: '100%', height: '400px', minHeight: "400px", height: 'auto' }}>
              {/* <div className="common-game-bg"></div> */}
              <img className="common-game-bg" src={game.logo ? require(`../../assets/images/${game?.logo}`) : ''} alt={"Play poki games - " + game?.title} />
              <div className="common-game-bg-overlay-banner">
                <div className="center-content">
                  <img src={game.logo ? require(`../../assets/images/${game?.logo}`) : ''} alt={"Play poki games - " + game?.title} />
                  <a href={`/games/${gameId}`}><button className="gameBtn mt-3">LET'S PLAY</button></a>
                </div>
              </div>
            </div>
            {<AdComponent
              client="ca-pub-2248973218739803"
              slot="6874956263"
              format="auto"
              style={{ display: 'block' }}
            />}
            <div className="fs-18 text-white mt-5 description">
              <p className="m-0 mb-3">{game.description1}</p>

              <p className="m-0 mb-3">{game.description2}</p>

              <p className="m-0 mb-3">{game.description3}</p>

              <p className="m-0 mb-3">{game.description4}</p>

              <p className="m-0 ">{game.description5}</p>
            </div>
            {
              <div className="d-flex flex-wrap gap-1 tags">
                {
                  game.tags?.map((tagName, index) => {
                    return <div key={index} className="tag text-nowrap">{tagName}</div>
                  })
                }
              </div>
            }
            <div>
              <div className='row m-0 g-1 mt-4'>
                {
                  data.map((element, index) => {
                    return <Fragment key={index}>
                      {(index == 18 || index == 36 || index == 54) &&
                        <div className='col-12'>
                          <AdComponent
                            client="ca-pub-2248973218739803"
                            slot="6874956263"
                            format="auto"
                            style={{ display: 'block' }}
                          />
                        </div>
                      }
                      <div key={index} className='col-6 col-sm-4 col-lg-2 p-2'>
                        <GameCard data={element} />
                      </div>
                    </Fragment>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter vertical-align-center" centered>

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-center">ADVERTISEMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {<AdComponent
            client="ca-pub-2248973218739803"
            slot="6874956263"
            format="auto"
            style={{ display: 'block' }}
          />}
        </Modal.Body>
      </Modal>

    </>
  );
}

export default ViewMaster(Game);
